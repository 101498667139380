import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import CondensedArticles from "../components/condensed-articles"

export default function Home() {
  return (
    
    <div>
      <Layout>

        <br>
        </br>
       Legal

    
      </Layout>
    </div>
  );
}
